import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import {getAll as getTests} from "app/requests/admin/tests";
import Checkbox from "components/helpers/form/checkbox";
import ContentEditor from "components/helpers/form/content-editor";
import Notification from "components/helpers/notification";
import Loading from "components/pages/loading";

const templates = ["Default", "Home", "Test"];

function AdminPagesForm({onChange, onSubmit, value}) {
  const [tests, setTests] = useState(null);
  const testTemplate = value("template") === "Test";

  useEffect(() => {
    if(tests) { return; }
    if(!testTemplate) { return; }

    getTests().then((data) => setTests(data.tests));
  }, [testTemplate]);

  if(testTemplate && !tests) { return <Loading />; }

  return (
    <div className="max-w-sm mx-auto">
      <form onSubmit={onSubmit}>
        <div className="mb-4">
          <Checkbox
            checked={value("active", false)}
            id="record-active"
            label="Active"
            name="active"
            onChange={onChange}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="record-rank">Rank</label>
          <input
            id="record-rank"
            name="rank"
            onChange={onChange}
            placeholder="100"
            required={true}
            type="text"
            value={value("rank")}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="record-template">Template</label>
          <select
            id="record-template"
            name="template"
            onChange={onChange}
            required={true}
            value={value("template")}
          >
            {templates.map((option) => (
              <option key={option} value={option}>{option}</option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <label htmlFor="record-name">Name</label>
          <input
            id="record-name"
            name="name"
            onChange={onChange}
            placeholder="Contact"
            required={true}
            type="text"
            value={value("name")}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="record-path">Path</label>
          <input
            id="record-path"
            name="path"
            onChange={onChange}
            placeholder="contact"
            required={true}
            type="text"
            value={value("path")}
          />
        </div>
        {testTemplate && (
          <div className="mb-4">
            <label htmlFor="record-test-id">Test</label>
            {tests.length === 0 ? (
              <Notification type="error">There are no tests available. You must <a href="/admin/tests">create one</a> before creating a page using the test template.</Notification>
            ) : (
              <select
                id="record-test-id"
                name="test_id"
                onChange={onChange}
                required={true}
                value={value("test_id")}
              >
                <option value="">Select Test</option>
                {tests.map(({id, name}) => (
                  <option key={id} value={id}>{name}</option>
                ))}
              </select>
            )}
          </div>
        )}
        <div className="mb-4">
          <label htmlFor="record-content-disabled">Content</label>
          <ContentEditor
            id="record-content"
            name="content"
            onChange={onChange}
            value={value("content")}
          />
        </div>
        <div className="text-center">
          <button type="submit" className="btn btn-blue">Submit</button>
        </div>
      </form>
    </div>
  );
}

AdminPagesForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  value: PropTypes.func.isRequired
};

export default AdminPagesForm;
