import {SET_APP} from "app//actions/app";

export default (state = {}, action) => {
  switch(action.type) {
    case SET_APP:
      return {...action.app};
    default:
      return state;
  }
};
