import PropTypes from "prop-types";

function Notification({children, content, onClose, type, ...props}) {
  let typeClasses = "bg-green-100 text-green-700";
  if(type === "error") { typeClasses = "bg-red-100 text-red-700"; }

  return (
    <div className={`mb-2 notification py-3 pl-4 pr-10 relative rounded-lg ${typeClasses}`} role="alert" {...props}>
      <div>{content || children}</div>
      {onClose && (
        <button aria-label="Close" className="absolute inset-y-0 right-0 flex items-center mr-4" onClick={onClose} type="button">
          <i className="fas fa-times" />
        </button>
      )}
    </div>
  );
}

Notification.defaultProps = {
  children: null,
  content: null,
  onClose: null,
  type: "success"
};

Notification.propTypes = {
  children: PropTypes.node,
  content: PropTypes.string,
  onClose: PropTypes.func,
  type: PropTypes.string
};

export default Notification;
