import {NavLink} from "react-router-dom";

export default function AdminNavbar() {
  return (
    <nav className="bg-dark bg-green">
      <div className="container">
        <div className="flex items-center justify-end">
          <div>
            <NavLink className="mx-2" to="/admin/sections">Content</NavLink>
            <NavLink className="mx-2" to="/admin/pages">Pages</NavLink>
            <NavLink className="mx-2" to="/admin/tests">Tests</NavLink>
            <NavLink className="mx-2" to="/admin/users">Users</NavLink>
          </div>
        </div>
      </div>
    </nav>
  );
}
