import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import {createNotification} from "app/actions/notifications";
import {setTest} from "app/actions/test";
import {getResults} from "app/requests/public/tests";
import {destroy as destroyResponse} from "app/requests/public/test-responses";
import useAppContext from "lib/hooks/use-app-context";
import useStarPresent from "lib/hooks/use-star-present";
import Answers from "./answers";
import style from "../style.scss";

function Results({testResponse}) {
  const dispatch = useAppContext()[1];
  const [results, setResults] = useState(null);
  const starPresent = useStarPresent();

  useEffect(() => {
    getResults(testResponse.test_id).then((data) => {
      setResults({
        scores: [...data.responses].sort((x, y) => y.score - x.score),
        starScores: [...data.responses].sort((x, y) => y.star_score - x.star_score)
      });

      dispatch(setTest(data.test));
    });
  }, [testResponse.test_id]);

  if(!results) { return null; }

  const total = testResponse.question_responses.length;
  const onRedo = () => {
    if(starPresent) { return; }
    if(!window.confirm("Are you sure you want to delete your old results?")) { return; }

    destroyResponse(testResponse.test_id).then((data) => {
      if(data.message) {
        dispatch(createNotification({
          content: data.message,
          type: data.success ? "success" : "error"
        }));
      }
      if(data.success) { window.location.reload(); }
    });
  };

  return (
    <div className="container">
      <div className="text-center">
        <h1>Your Results</h1>
        <h3>{testResponse.score}/{total}</h3>
        {!starPresent && (
          <button onClick={onRedo} type="button">Redo</button>
        )}
      </div>
      <h1 className="text-center">Leaderboard</h1>
      <div className={style.results}>
        <div className={style.column}>
          <h3>Scores</h3>
          <div className={style.scores}>
            {results.scores.map(({score, user}) => (
              <div key={user.id} className={style.response}>
                <div>{user.first_name} {user.last_name}</div>
                <div>{score}</div>
              </div>
            ))}
          </div>
        </div>
        <div className={style.column}>
          <h3>Star Scores</h3>
          <div className={style.scores}>
            {results.starScores.map(({star_score: score, user}) => (
              <div key={user.id} className={style.response}>
                <div>{user.first_name} {user.last_name}</div>
                <div>{score}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Answers />
    </div>
  );
}

Results.propTypes = {
  testResponse: PropTypes.shape({
    question_responses: PropTypes.arrayOf(
      PropTypes.shape({id: PropTypes.number.isRequired}).isRequired
    ).isRequired,
    score: PropTypes.number.isRequired,
    test_id: PropTypes.number.isRequired
  }).isRequired
};

export default Results;
