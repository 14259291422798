import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import {getAll as getUsers} from "app/requests/admin/users";
import ContentEditor from "components/helpers/form/content-editor";
import Loading from "components/pages/loading";
import Answers from "./form/answers";

function AdminTestQuestionsForm({onChange, onSubmit, value}) {
  const [users, setUsers] = useState(null);

  useEffect(() => {
    if(users) { return; }

    getUsers().then((data) => setUsers(data.users));
  }, []);

  if(!users) { return <Loading />; }

  const onAddAnswer = () => {
    const answers = value("answers") || [];

    onChange({target: {name: "answers", value: [...answers, {}]}});
  };

  return (
    <div className="max-w-sm mx-auto">
      <form onSubmit={onSubmit}>
        <div className="mb-4">
          <label htmlFor="record-content">Content</label>
          <ContentEditor
            id="record-content"
            name="content"
            onChange={onChange}
            value={value("content")}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="record-creator-id">Creator</label>
          <select
            id="record-creator-id"
            name="creator_id"
            onChange={onChange}
            required={true}
            value={value("creator_id")}
          >
            <option value="">Select Creator</option>
            {users.map((user) => (
              <option key={user.id} value={user.id}>{user.first_name} {user.last_name}</option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <label htmlFor="record-explanation">Explanation</label>
          <ContentEditor
            id="record-explanation"
            name="explanation"
            onChange={onChange}
            value={value("explanation")}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="record-name">Name</label>
          <input
            id="record-name"
            name="name"
            onChange={onChange}
            placeholder="Name"
            required={true}
            type="text"
            value={value("name")}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="record-rank">Rank</label>
          <input
            id="record-rank"
            name="rank"
            onChange={onChange}
            placeholder="100"
            required={true}
            type="text"
            value={value("rank")}
          />
        </div>
        <Answers onChange={onChange} value={value("answers") || []} />
        <div className="flex items-center justify-between">
          <button onClick={onAddAnswer} type="button" className="btn btn-green">Add Answer</button>
          <button type="submit" className="btn btn-blue">Submit</button>
        </div>
      </form>
    </div>
  );
}

AdminTestQuestionsForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  value: PropTypes.func.isRequired
};

export default AdminTestQuestionsForm;
