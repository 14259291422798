import {useEffect, useState} from "react";
import {getAll as getUsers} from "app/requests/public/user";
import Loading from "components/pages/loading";
import SignIn from "./sign-in";
import SignUp from "./sign-up";
import style from "./style.scss";

export default function Authenticate() {
  const [status, setStatus] = useState(null);
  const [users, setUsers] = useState(null);
  const onBack = () => setStatus(false);

  useEffect(() => {
    getUsers().then((data) => setUsers(data.users));
  }, []);

  const onSignIn = (user) => setStatus({user});
  const onSignUp = () => setStatus({signUp: true});

  if(!users) { return <Loading />; }
  if(status && status.signUp) { return <SignUp onBack={onBack} />; }
  if(status && status.user) { return <SignIn onBack={onBack} user={status.user} />; }

  return (
    <div className="container">
      <h1 className="text-center">Who are you?</h1>
      <div className="max-w-sm mx-auto">
        <div className={style.authenticate}>
          {users.map((user) => (
            <button key={user.id} onClick={() => onSignIn(user)} type="button">
              {user.image ? (
                <img alt={`${user.first_name} ${user.last_name}`} src={user.image} />
              ) : (
                <>
                  <div>{user.first_name}</div>
                  <div>{user.last_name}</div>
                </>
              )}
            </button>
          ))}
          <button className={style.signup} onClick={onSignUp} type="button">
            <div>Add</div>
            <div>New</div>
          </button>
        </div>
      </div>
    </div>
  );
}
