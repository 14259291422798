/* eslint-disable import/prefer-default-export */
import headers from "app/requests/headers";

export const get = (id) => (
  fetch(`/api/public/tests/${id}`, {
    headers: headers(),
    method: "GET"
  }).then((response) => response.json())
);

export const getResults = (id) => (
  fetch(`/api/public/tests/${id}/results`, {
    headers: headers(),
    method: "GET"
  }).then((response) => response.json())
);
