import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {createNotification} from "app/actions/notifications";
import {setTest} from "app/actions/test";
import {get as getTest} from "app/requests/public/tests";
import {get as getResponse, update as updateResponse} from "app/requests/public/test-responses";
import Content from "components/helpers/action-text/content";
import Loading from "components/pages/loading";
import useAppContext from "lib/hooks/use-app-context";
import Authenticate from "./authenticate";
import Results from "./results";
import style from "./style.scss";

export default function TestsShow() {
  const [{test, user}, dispatch] = useAppContext();
  const {id} = useParams();
  const [answerID, setAnswerID] = useState(null);
  const [answered, setAnswered] = useState(false);
  const [responses, setResponses] = useState(null);
  const [testResponse, setTestResponse] = useState(null);

  useEffect(() => {
    if(test && test.id === id) { return; }

    getTest(id).then((data) => dispatch(setTest(data.test)));
  }, [id]);

  useEffect(() => {
    if(!test) { return; }
    if(!user) { return; }

    getResponse(id).then((data) => setTestResponse(data.test_response));
  }, [test, user]);

  useEffect(() => {
    if(!testResponse) { return; }
    if(testResponse.completed) { return; }

    const userResponses = testResponse.question_responses;
    const questionResponses = test.questions.map((question) => {
      const response = userResponses.find((r) => r.question_id === question.id);

      return response || {question_id: question.id};
    });

    setResponses(questionResponses);
  }, [testResponse]);

  useEffect(() => {
    if(!responses) { return; }

    const question = responses.find((response) => !response.answer_id);
    if(question) { return; }

    const record = {question_responses_attributes: responses};

    updateResponse(id, {test_response: record}).then((data) => {
      if(data.message) {
        dispatch(createNotification({
          content: data.message,
          type: data.success ? "success" : "error"
        }));
      }

      setTestResponse(data.test_response);
    });
  }, [responses]);

  if(testResponse && testResponse.completed) { return <Results testResponse={testResponse} />; }
  if(!user) { return <Authenticate />; }
  if(!responses) { return <Loading />; }
  if(!test) { return <Loading />; }

  const questionIndex = responses.findIndex((response) => !response.answer_id);
  const question = test.questions[questionIndex];

  if(!question) { return <Loading />; }

  const onNext = () => {
    const updatedResponses = [...responses];
    updatedResponses[questionIndex].answer_id = answerID;

    setAnswerID(null);
    setAnswered(false);
    setResponses(updatedResponses);
  };

  const onResponse = () => {
    if(!answerID) { return; }

    test.star.id === user.id ? setAnswered(true) : onNext();
  };

  return (
    <div className="container">
      <div className="max-w-screen-md mx-auto">
        <h1 className="text-center">Question {questionIndex + 1}</h1>
        <small className="block pb-2 text-center">By {question.creator.first_name} {question.creator.last_name}</small>
        {question.content && (
          <div className="custom-html">
            <Content className="mb-4" html={question.content} />
          </div>
        )}
        <div className="gap-5 grid my-5 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          {question.answers.map((answer) => {
            const selected = answer.id === answerID;
            const color = selected ? "blue" : "gray";
            const correct = answered && answer.correct;
            const css = `
              ${correct ? "bg-green" : ""}
              border-4 border-${color}
              flex flex-col justify-between p-2
            `;

            return (
              <div key={answer.id} className={css}>
                <div className="custom-html">
                  <Content className="mb-4" html={answer.content} />
                </div>
                {answered ? (
                  <div className={style.users}>
                    {answer.users.map((answerUser) => (
                      <div key={answerUser.id} className={style.user}>
                        {answerUser.image ? (
                          <img alt={`${answerUser.first_name} ${answerUser.last_name}`} src={answerUser.image} />
                        ) : (
                          <>
                            <div>{answerUser.first_name}</div>
                            <div>{answerUser.last_name}</div>
                          </>
                        )}
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="text-center">
                    <button className={`btn btn-${color}`} onClick={() => setAnswerID(answer.id)} type="button">Select</button>
                  </div>
                )}
              </div>
            );
          })}
        </div>
        {answered && question.explanation && (
          <div className="custom-html">
            <Content className="mb-4" html={question.explanation} />
          </div>
        )}
        {answered ? (
          <button className="block btn btn-blue mx-auto" onClick={onNext} type="button">Next</button>
        ) : (
          <button className="block btn btn-green mx-auto" onClick={onResponse} type="button">Submit</button>
        )}
      </div>
    </div>
  );
}
