import PropTypes from "prop-types";
import {useEffect} from "react";
import {Link} from "react-router-dom";
import {setTest} from "app/actions/test";
import {get as getTest} from "app/requests/public/tests";
import Content from "components/helpers/action-text/content";
import Loading from "components/pages/loading";
import useAppContext from "lib/hooks/use-app-context";

function Test({page}) {
  const [{test}, dispatch] = useAppContext();

  useEffect(() => {
    getTest(page.test_id).then((data) => dispatch(setTest(data.test)));
  }, [page.test_id]);

  if(!test) { return <Loading />; }

  return (
    <div className="container">
      <div className="max-w-screen-md mx-auto">
        <h1>{test.name}</h1>
        <div className="custom-html">
          <Content className="mb-4" html={page.content} />
        </div>
        <div className="my-4">There are {test.questions.length} questions</div>
        <Link className="btn btn-green" to={`/tests/${test.id}`}>Begin</Link>
      </div>
    </div>
  );
}

Test.propTypes = {
  page: PropTypes.shape({
    content: PropTypes.string.isRequired,
    test_id: PropTypes.number.isRequired
  }).isRequired
};

export default Test;
