import headers from "app/requests/headers";

export const create = (params) => (
  fetch("/api/public/user", {
    body: JSON.stringify(params),
    headers: headers(),
    method: "POST"
  }).then((response) => response.json())
);

export const getAll = () => (
  fetch("/api/public/users", {
    headers: headers(),
    method: "GET"
  }).then((response) => response.json())
);

export const update = (params) => (
  fetch("/api/public/user", {
    body: JSON.stringify(params),
    headers: headers(),
    method: "PUT"
  }).then((response) => response.json())
);
