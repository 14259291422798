import PropTypes from "prop-types";
import Content from "components/helpers/action-text/content";

function Default({page}) {
  return (
    <div className="container">
      <div className="max-w-screen-md mx-auto">
        <div className="custom-html">
          <Content html={page.content} />
        </div>
      </div>
    </div>
  );
}

Default.propTypes = {
  page: PropTypes.shape({
    content: PropTypes.string.isRequired
  }).isRequired
};

export default Default;
