import {NavLink} from "react-router-dom";
import tomify from "app/assets/images/tomify.png";
import {createNotification} from "app/actions/notifications";
import {destroy as destroySession} from "app/requests/public/session";
import useAppContext from "lib/hooks/use-app-context";
import useContent from "lib/hooks/use-content";

function DefaultNavbar() {
  return (
    <nav className="navbar">
      <div className="container">
        <div className="flex items-center justify-between">
          <a className="inline-block" href="/">
            <i className="block fas fa-spin fa-spinner fa-2x my-6" />
          </a>
          <div>
            <NavLink className="mx-2" to="/">Jena Trivia</NavLink>
            <NavLink className="mx-2" to="/session">Sign In</NavLink>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default function Navbar() {
  const [{app: {loading}, pages, user}, dispatch] = useAppContext();
  const logo = useContent("Navbar", "Logo");
  const onSignOut = () => {
    destroySession().then((data) => {
      if(data.success) {
        window.location.href = `/?message=${data.message}&type=success`;
      } else {
        dispatch(createNotification({content: data.message, type: "error"}));
      }
    });
  };

  if(loading) { return <DefaultNavbar />; }

  const defaultPages = pages.filter((page) => page.path !== "home");

  return (
    <nav className="navbar">
      <div className="container">
        <div className="flex items-center justify-between">
          <a className="inline-block" href="/">
            <img alt="Logo" src={logo || tomify} />
          </a>
          <div className="text-right">
            <div className="mt-4">
              {defaultPages.map((page) => (
                <NavLink className="mx-2" key={page.path} to={`/${page.path}`}>{page.name}</NavLink>
              ))}
              {user ? (
                <>
                  <NavLink className="mx-2" to="/profile">Profile</NavLink>
                  <button className="mx-2" onClick={onSignOut} type="button">Sign Out</button>
                </>
              ) : (
                <NavLink className="mx-2" to="/session">Sign In</NavLink>
              )}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}
