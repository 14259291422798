import "@tomprats/font-awesome-pro/js/all";
import "core-js/stable";
import {useReducer} from "react";
import {BrowserRouter} from "react-router-dom";
import "regenerator-runtime/runtime";
import Context from "app/context";
import Reducer from "app/reducer";
import useScrollbarWidth from "lib/hooks/use-scrollbar-width";
import Router from "./router";

window.FontAwesome.config.autoReplaceSvg = "nest";

const initialState = {
  app: {loading: true, user: null},
  notifications: [],
  pages: []
};

export default function Application() {
  const [state, dispatch] = useReducer(Reducer, initialState);

  useScrollbarWidth();

  return (
    <Context.Provider value={[state, dispatch]}>
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </Context.Provider>
  );
}
