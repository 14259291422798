import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import useAppContext from "lib/hooks/use-app-context";
import Default from "./default";
import Home from "./home";
import Test from "./test";

const getPage = ({pages, pathname}) => {
  if(pathname === "/") {
    return pages.find(({path}) => path === "home") || {
      content: "",
      name: "Home",
      path: "home",
      template: "home"
    };
  }

  return pages.find(({path}) => `/${path}` === pathname);
};

export default function Template() {
  const location = useLocation();
  const [{pages}] = useAppContext();
  const [page, setPage] = useState(() => getPage({pages, pathname: location.pathname}));

  useEffect(() => {
    setPage(getPage({pages, pathname: location.pathname}));
  }, [location.pathname, pages]);

  switch(page.template) {
    case "Home": return <Home page={page} />;
    case "Test": return <Test page={page} />;
    default: return <Default page={page} />;
  }
}
