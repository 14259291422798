import Content from "components/helpers/action-text/content";
import useAppContext from "lib/hooks/use-app-context";
import useStarPresent from "lib/hooks/use-star-present";
import style from "../style.scss";

export default function Answers() {
  const [{test}] = useAppContext();
  const starPresent = useStarPresent();

  if(!starPresent) { return null; }

  return (
    <div className="max-w-screen-md mx-auto">
      <h1 className="text-center">Answers</h1>
      {test.questions.map((question, index) => (
        <div key={question.id}>
          <h3 className="text-center">Question {index + 1}</h3>
          <small className="block pb-2 text-center">By {question.creator.first_name} {question.creator.last_name}</small>
          {question.content && (
            <div className="custom-html">
              <Content className="mb-4" html={question.content} />
            </div>
          )}
          <div className="gap-5 grid my-5 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            {question.answers.map((answer) => {
              const starAnswer = answer.users.find(({id}) => id === test.star.id);
              const color = starAnswer ? "blue" : "gray";
              const css = `
                ${answer.correct ? "bg-green" : ""}
                border-4 border-${color}
                flex flex-col justify-between p-2
              `;

              return (
                <div key={answer.id} className={css}>
                  <div className="custom-html">
                    <Content className="mb-4" html={answer.content} />
                  </div>
                  <div className={style.users}>
                    {answer.users.map((answerUser) => (
                      <div key={answerUser.id} className={style.user}>
                      </div>
                    ))}
                  </div>
                </div>
              );
            })}
          </div>
          {question.explanation && (
            <div className="custom-html">
              <Content className="mb-4" html={question.explanation} />
            </div>
          )}
        </div>
      ))}
    </div>
  );
}
