import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import {getAll as getUsers} from "app/requests/admin/users";
import Loading from "components/pages/loading";

function AdminTestsForm({onChange, onSubmit, value}) {
  const [users, setUsers] = useState(null);

  useEffect(() => {
    if(users) { return; }

    getUsers().then((data) => setUsers(data.users));
  }, []);

  if(!users) { return <Loading />; }

  return (
    <div className="max-w-sm mx-auto">
      <form onSubmit={onSubmit}>
        <div className="mb-4">
          <label htmlFor="record-star-id">Star</label>
          <select
            id="record-star-id"
            name="star_id"
            onChange={onChange}
            required={true}
            value={value("star_id")}
          >
            <option value="">Select Star</option>
            {users.map((user) => (
              <option key={user.id} value={user.id}>{user.first_name} {user.last_name}</option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <label htmlFor="record-name">Name</label>
          <input
            id="record-name"
            name="name"
            onChange={onChange}
            placeholder="Anatomy of a Dragon"
            required={true}
            type="text"
            value={value("name")}
          />
        </div>
        <div className="text-center">
          <button type="submit" className="btn btn-blue">Submit</button>
        </div>
      </form>
    </div>
  );
}

AdminTestsForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  value: PropTypes.func.isRequired
};

export default AdminTestsForm;
