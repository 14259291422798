import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import useAppContext from "./use-app-context";
import useContent from "./use-content";
import useScript from "./use-script";

export default function useAnalytics() {
  const [{app: {environment}}] = useAppContext();
  const code = useContent("General", "Google Analytics Code");
  const location = useLocation();
  const [loaded, setLoaded] = useState(false);
  const development = environment !== "production";

  useScript(development ? {} : {url: "https://www.google-analytics.com/analytics.js"});

  useEffect(() => {
    if(development) { return; }
    if(!code) { return; }

    window.ga=window.ga||function(){(ga.q=ga.q||[]).push(arguments)};ga.l=+new Date; /* eslint-disable-line */
    window.ga("create", code, "auto");

    setLoaded(true);
  }, [code, development]);

  useEffect(() => {
    if(!loaded) { return; }

    window.ga("send", "pageview", {page: location.pathname});
  }, [loaded, location.pathname]);
}
