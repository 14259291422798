import app from "app/reducers/app";
import notifications from "app/reducers/notifications";
import pages from "app/reducers/pages";
import sections from "app/reducers/sections";
import test from "app/reducers/test";
import user from "app/reducers/user";
import combineReducers from "lib/combine-reducers";

export default combineReducers({
  app,
  notifications,
  pages,
  sections,
  test,
  user
});
