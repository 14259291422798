import * as ActiveStorage from "@rails/activestorage";
import "@rails/actiontext";
import Rails from "@rails/ujs";
import {render} from "react-dom";
import "whatwg-fetch";
import Application from "components/application";
import "lib/trix";
import "app/assets/style.external.scss";
import "app/assets/style.scss";

Rails.start();
ActiveStorage.start();

document.addEventListener("DOMContentLoaded", () => {
  render(
    <Application />,
    document.querySelector("#app")
  );
});
