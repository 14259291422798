import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import {getDirectUploadUrl} from "lib/active-storage";

const labelClasses = [
  "after:absolute after:bg-gray-100 after:block after:border-l-inherit after:bottom-0 after:content-['Browse']",
  "after:px-3 after:py-1.5 after:right-0 after:rounded-r after:top-0 after:z-30",
  "absolute border border-gray-300 left-0 px-3 py-1.5 right-0 rounded top-0 z-10"
];

function File({id, onChange, ...props}) {
  const [directUploadUrl, setDirectUploadUrl] = useState(null);
  const [fileName, setFileName] = useState(null);
  const onFileChange = (e) => {
    const file = e.target.files[0];

    setFileName(file && file.name);
    onChange && onChange(e);
  };

  useEffect(() => setDirectUploadUrl(getDirectUploadUrl()), []);

  return (
    <div className="inline-block relative w-full">
      <input
        className="h-[calc(2.25em+2px)] m-0 opacity-0 relative w-full z-20"
        data-direct-upload-url={directUploadUrl}
        id={id}
        type="file"
        {...props}
        onChange={onFileChange}
      />
      <label className={labelClasses.join(" ")} htmlFor={id}>{fileName || "Choose file"}</label>
    </div>
  );
}

File.defaultProps = {onChange: null};
File.propTypes = {id: PropTypes.string.isRequired, onChange: PropTypes.func};

export default File;
