import PropTypes from "prop-types";
import {Children, Fragment, isValidElement} from "react";
import {Switch} from "react-router-dom";

function flatten(children) {
  const flattenedChildren = [];

  Children.forEach(children, (child) => {
    if(isValidElement(child)) {
      if(child.type === Fragment) {
        flattenedChildren.push(...flatten(child.props.children));
      } else {
        flattenedChildren.push(child);
      }
    }
  });

  return flattenedChildren;
}

// react-router doesn't have support for React fragments in <Switch />
// https://github.com/ReactTraining/react-router/issues/5785
function BetterSwitch({children}) {
  return <Switch>{flatten(children)}</Switch>;
}

BetterSwitch.propTypes = {children: PropTypes.node.isRequired};

export default BetterSwitch;
