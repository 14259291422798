import {dig} from "lib/ext/object";
import useAppContext from "./use-app-context";

export default function useStarPresent() {
  const [{test}] = useAppContext();

  if(!dig(test, "questions", 0, "answers", 0, "users")) { return false; }

  return test.questions[0].answers.some(({users}) => (
    users.some(({id}) => id === test.star.id)
  ));
}
