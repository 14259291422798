import {useEffect, useState} from "react";
import {getAll as getRecords} from "app/requests/admin/sections";
import Loading from "components/pages/loading";

export default function AdminSectionsList() {
  const [records, setRecords] = useState([]);

  useEffect(() => {
    getRecords().then((data) => setRecords(data.sections));
  }, []);

  if(!records) { return <Loading />; }

  return (
    <div className="container">
      <div className="max-w-screen-lg mx-auto">
        <h1 className="text-center">Sections</h1>
        <div className="table-responsive">
          <table className="table text-center">
            <thead>
              <tr>
                <th>Section</th>
                <th>Content</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {records.map((record) => (
                <tr key={record.id}>
                  <td>{record.name}</td>
                  <td>{record.contents.map(({name}) => name).join(", ")}</td>
                  <td>
                    <a className="btn btn-sm btn-blue" href={`/admin/sections/${record.id}`}>Edit</a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
