import PropTypes from "prop-types";

function Checkbox({checked, children, id, label, ...props}) {
  return (
    <label className="inline-flex items-center space-x-1" htmlFor={id}>
      <input
        checked={checked || false}
        className="appearance-none border border-gray-300 h-5 rounded-md w-5 checked:bg-blue-600 checked:bg-check checked:border-transparent focus:outline-none"
        id={id}
        type="checkbox"
        {...props}
      />
      <span>{label || children}</span>
    </label>
  );
}

Checkbox.defaultProps = {checked: false, children: null, label: null};
Checkbox.propTypes = {
  checked: PropTypes.bool,
  children: PropTypes.node,
  id: PropTypes.string.isRequired,
  label: PropTypes.string
};

export default Checkbox;
