/* eslint-disable react/no-array-index-key */
import PropTypes from "prop-types";
import Answer from "./answer";

function Answers({value, ...props}) {
  const values = [...value];

  return (
    <>
      {values.map((_, index) => (
        <Answer
          key={index}
          index={index}
          values={values}
          {...props}
        />
      ))}
    </>
  );
}

Answers.propTypes = {
  value: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired
};

export default Answers;
